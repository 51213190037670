import slider_main from '../images/slider_main.jpg'
import image1 from '../images/slider1.jpg'
import image2 from '../images/slider2.jpg'
import image3 from '../images/slider3.jpg'
import image4 from '../images/slider4.jpg'

export const images = [slider_main,image1, image2, image3, image4]

const imageByIndex = (index) => images[index % images.length]

export default imageByIndex

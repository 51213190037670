
import react from "react"
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'



function Photos() {

    let data = [
        {
          "title": "Silver Wedding Anniversary of Venigalla Couple and Gadde Couple",
          "vid": "gFcpotkLlUA",
          "date":'2017-01-03',
        },
        {
            "title": "Message by Pastor John Wesley . Telugu Christian Church-New York",
            "vid": "yoa2lesnfco",
            "date":'2017-01-03',
        },
        {
            "title": "Kids @ UTCF -NY",
            "vid": "9tt8dn2sFs8",
            "date":'2017-01-03',
        },
        {
            "title": "Kids @ UTCF -NY",
            "vid": "9tt8dn2sFs8",
            "date":'2017-01-03',
        },
        {
            "title": "Sharoon Vemu",
            "vid": "TrOnnP48KtE",
            "date":'2017-01-03',
        },
        {
            "title": "2016 United Telugu Christmas Carols. New York,USA",
            "vid": "LVJJgI_4XCs",
            "date":'2017-01-03',
        },
        {
            "title": "UTCF Silver Jubilee Celebrations in New York - USA - TV9",
            "vid": "CR4gzhSGLOM",
            "date":'2017-01-03',
        },
    ]

    return(
        <div>
            <div class="container my-12 mx-auto px-4 md:px-12">
    <div class="flex flex-wrap -mx-1 lg:-mx-4">





{data.map((i) => (
           <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">

           {/* <!-- Article --> */}
           <article class="overflow-hidden rounded-lg shadow-lg">
          


                   <LiteYouTubeEmbed  class="block h-auto w-full" id={i.vid}/>
     

               <header class="flex items-center justify-between leading-tight p-2 md:p-4">
                   <h1 class="text-lg">
                       <p class="no-underline hover:underline text-lg font-semibold text-black" href="#">
                          {i.title}
                       </p>
                   </h1>

               </header>

               

               <footer class="flex items-center justify-between leading-none p-2 md:p-4">
                   <p class="flex items-center no-underline hover:underline text-black" href="#">
                      
                       <p class="ml-2 text-sm">
                           {i.date}
                       </p>
                   </p>
               </footer>

           </article>
           {/* <!-- END Article --> */}

       </div>
))}
    </div>
</div>
        </div>
    )
}


export default Photos
import EmblaCarousel from './Carousel'
import Votd from './Votd';
import { useEffect } from 'react';

export default function Home() {

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://app.mailjet.com/pas-nc-embedded-v1.js";
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  })

  const OPTIONS = { loop: true }
  const SLIDE_COUNT = 4
  const SLIDES = Array.from(Array(SLIDE_COUNT).keys())

    return (
        <>
    <EmblaCarousel slides={SLIDES} options={OPTIONS} />
      
      <Votd/>
      <div>
      <iframe title='newsletter' data-w-type="embedded" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://093r9.mjt.lu/wgt/093r9/jht/form?c=d4014cfd" width="100%" style={{height: "0"}}></iframe>

<script type="text/javascript" src="https://app.mailjet.com/pas-nc-embedded-v1.js"></script>

      </div>
      </>
    )
}

